import type { ValuesByLocale } from '~/i18n/config'

type MediasByLocale = ValuesByLocale<string>

export const medias = {
  pdf: {
    bio: {
      fr: '/pdf/Liste des marqueurs biologiques.pdf',
      en: '/pdf/List of biological markers.pdf'
    } satisfies MediasByLocale,
    exams: {
      fr: '/pdf/Liste des examens.pdf',
      en: '/pdf/List of examinations.pdf'
    } satisfies MediasByLocale
  },
  image: {
    qrCodeMobileApp: '/img/qr-code-mobile-app.png'
  }
} as const
